import * as React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import styled from 'styled-components'

import { Query, File } from '@generated/graphql'

import Layout from '@components/Layout'
import { GLink, Text, Grid, Box } from '@components/UI'

const PhotosGrid = styled(Grid).attrs({
  spacing: [1, 2, 3],
  numCols: 3,
  pb: 5
})``

const getPath = (absolutePath: string) =>
  absolutePath
    .match(/photos.*$/)[0]
    .replace(/_square/, '')
    .replace(/\..*$/, '')

// Example:
//   in:  "/content/photos_square/39_wonderfruit.jpg"
//   out: "39_wonderfruit"
const getBaseFileName = (absolutePath: string) =>
  absolutePath.match(/([^/]+)\..*/)[1]

const Photos = ({ data }: { data: Query }) => {
  const nodes = data.allFile.nodes
  const photosMd = data.allMarkdownRemark.nodes[0].frontmatter.photos

  const photosInfo = _.keyBy(
    data.allMarkdownRemark.nodes[0].frontmatter.photos,
    o => o.fileName
  )

  const nodesByFileName = _.keyBy(nodes, node =>
    getBaseFileName(node.absolutePath)
  )
  return (
    <Layout title="Photos">
      <PhotosGrid
        elements={photosMd}
        render={(node: any) => {
          const config = nodesByFileName[node.fileName]
          const { label } = photosInfo[node.fileName]
          return (
            <div>
              <GLink to={getPath(config.absolutePath)}>
                <Img fluid={config.childImageSharp.fluid} />
              </GLink>
              <Text textAlign="right" pb={3} pt={1} pl={1} fontSize={[0, 1]}>
                {label}
              </Text>
            </div>
          )
        }}
      />
    </Layout>
  )
}

export default Photos

export const query = graphql`
  {
    allFile(
      filter: {
        absolutePath: { regex: "/content.photos_square/" }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        absolutePath
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content.photos.info.md/" } }
    ) {
      nodes {
        frontmatter {
          photos {
            fileName
            label
            title
          }
        }
      }
    }
  }
`
